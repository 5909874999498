/* Products.css */

/* Title Animation */

@keyframes fadeIn {
    from {
        opacity: 0 !important;
        transform: translateY(-20px) !important;
    }
    to {
        opacity: 1 !important;
        transform: translateY(0) !important;
    }
}

/* Image Hover Effect */
.product-card:hover .product-image {
    border-color: orange !important;
    transform: scale(1.05) !important;
    transition: transform 0.3s ease-in-out !important;
}

.custom-card-meta {
    color: orange !important;
    font-size: 20px !important;
    text-align: center !important;
    /* Add any other styles you want to override */
}
