/* Styles for the carousel images */
.aboutImage {
    width: 100%; /* Adjust as needed */
    height: 600px; /* Adjust as needed */
  }
  
  /* Define image sizes for different screen sizes */
  @media only screen and (max-width: 600px) {
    .aboutImage {
      height: 300px; /* Adjust as needed */
    }
  }
  
  @media only screen and (min-width: 600px) {
    .aboutImage {
      height: 300px; /* Adjust as needed */
    }
  }
  
  @media only screen and (min-width: 768px) {
    .aboutImage {
      height: 400px; /* Adjust as needed */
    }
  }
  
  @media only screen and (min-width: 992px) {
    .aboutImage {
      height: 600px; /* Adjust as needed */
    }
  }
  
  @media only screen and (min-width: 1200px) {
    .aboutImage {
      height: 600px; /* Adjust as needed */
    }
  }
  