/* Styles for the carousel images */
.carouselImages {
  width: 100%; /* Adjust as needed */
  height: 580px; /* Adjust as needed */
}

/* Default styles for the text */
.carouselText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #ffffff;
}

/* Define image sizes for different screen sizes */
@media only screen and (max-width: 600px) {
  .carouselImages {
    height: 300px; /* Adjust as needed */
  }
}

@media only screen and (min-width: 600px) {
  .carouselImages {
    height: 300px; /* Adjust as needed */
  }
}

@media only screen and (min-width: 768px) {
  .carouselImages {
    height: 400px; /* Adjust as needed */
  }
}

@media only screen and (min-width: 992px) {
  .carouselImages {
    height: 580px; /* Adjust as needed */
  }
}

@media only screen and (min-width: 1200px) {
  .carouselImages {
    height: 580px; /* Adjust as needed */
  }
}



/* Define font size and position for different screen sizes */
@media only screen and (max-width: 600px) {
  .carouselText {
    font-size: 18px; /* Adjust as needed */
    top: 32%; /* Adjust vertical position */
  }
}

@media only screen and (min-width: 600px) {
  .carouselText {
    font-size: 20px; /* Adjust as needed */
    top: 32%; /* Adjust vertical position */
  }
}

@media only screen and (min-width: 768px) {
  .carouselText {
    font-size: 24px; /* Adjust as needed */
    top: 30%; /* Adjust vertical position */
  }
}

@media only screen and (min-width: 992px) {
  .carouselText {
    font-size: 28px; /* Adjust as needed */
    top: 50%; /* Adjust vertical position */
  }
}

@media only screen and (min-width: 1200px) {
  .carouselText {
    font-size: 30px; /* Adjust as needed */
    top: 50%; /* Adjust vertical position */
  }
}
